<template>
    <modal :open="modalOpen" @close="$emit('closeModal');" @save="$emit('closeModal');" :modalData="modalData">
        <template v-slot:modalContent>            
            <v-card colored-border elevation="3" class="pa-4">
                <v-row>
                    <v-col cols="2" class="d-flex justify-center align-center">
                        <v-icon color="primary" class="mr-2">mdi-alert-circle</v-icon>
                    </v-col>
                    <v-col cols="10">
                        <v-skeleton-loader v-if="selectAppSettingLoading"
                            height="100px"
                            type="image"
                        ></v-skeleton-loader>
                        <div v-if="!selectAppSettingLoading" class="tip-tap-content" v-html="model.option_value"></div>
                    </v-col>
                </v-row>
            </v-card>
        </template>
    </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    props:['modalOpen'],
    emits: ['closeModal'],
    components:{
        Modal: () => import("@/components/common/modal"),
    },
    data: (vm) => ({
        modalLoading: false,
        modalData: {
            title: 'Information',
            saveBtnTxt: 'Ok',
            width: 1000,
            height: '500px'
        },
        model: null,
    }),
    computed: {
        ...mapGetters(["selectedAppSettingModel", "selectAppSettingLoading"])
    },
    watch: {
        selectedAppSettingModel: function(newVal) {
            this.model = {...this.model, ...newVal}
        }
    },
    created() {
        this.getSelectAppSettingData('INFO_PANEL');
    },
    methods: {
        ...mapActions(['getSelectAppSettingData'])
    }
}
</script>
